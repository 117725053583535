<template>
  <div class="manuscript1">
    <div class="header">
      <div class="nav_title">
        <div class="nav_title_group">
          <span>雪泥鸿爪</span>
          <p>01 千载谁是能书人</p>
        </div>
      </div>
      <div class="pagination_group">
        <div class="pagination"></div>
        <div  @click="handleInfoById({id:7,name:'雪泥鸿爪',label:'书法',info:'千载谁是能书人'})" class="all_work_btn">
            <span>全部作品</span>
        </div>
        <div class="btn_group">
          <img class="btn_left" src="./image/left.png" alt="" />
          <img class="btn_right" src="./image/right.png" alt="" />
        </div>
      </div>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          v-for="(item, index) in slideImgList"
          :key="index"
          :class="`delay_${index}`"
          data-aos="fade-up"
          class="swiper-slide wow fadeInUp fadeIn"
        >
          <div class="swiper_slide_img">
            <img class="swiper_slide_img_pr" :src="`${item.picture.replace('/images/','/thumb/images/')}`" alt="" />
            <img class="serach_img" src="./image/serach.png" alt="" @click="showImgViewer(item)" />
          </div>
          <p>{{ item.name }}</p>
        </div>
        <!-- <div data-aos="fade-up" @click="handleInfoById({id:7,name:'雪泥鸿爪',label:'书法',info:'千载谁是能书人'})"  class="swiper-slide wow fadeInUp fadeIn">
         <div class="swiper_slide_img">
            <img class="swiper_slide_img_pr" src="../components/image/all_work.png" alt="" />
          </div>
          <p style="opacity:0">全部作品</p>
        </div> -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
    <el-image-viewer
      class="img_viewer img_viewer_box"
      v-if="imgViewerVisible"
      :on-close="closeImgViewer"
      :url-list="imgViewerList"/>
    <MaxOpen ref="refMaxOpen"/>

  </div>
</template>

<script>
// import Swiper from 'swiper';
// import { slideImgList } from './data/slide'
import { request } from '@/utils/request'
import MaxOpen from '@/components/maxOpen/MaxOpen'

export default {
  components: {
    MaxOpen,
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {
      slideImgList: [],
      imgViewerVisible: false,
      imgViewerList: []
    }
  },
  mounted () {
    this.getAll()
    // eslint-disable-next-line no-new
  },
  methods: {
    /**
     * 跳转到详情
     */
    handleInfoById (query) {
      this.$emit('goInfoById', query)
      // this.$router.push({ path: '/list', query: query })
    },
    setSwiper () {
      // eslint-disable-next-line no-new
      new Swiper('.manuscript1 .swiper-container', {
        // direction: 'vertical', 垂直切换选项
        // loop: true, // 开启循环模式选项
        // slidesPerView: 2.3,
        slidesPerView: 'auto',
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
          clickable: true // 点分页时可以切换
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.manuscript1 .btn_right',
          prevEl: '.manuscript1 .btn_left'
        }
      })
    },
    getAll () {
      request('/api/artworks/content', 'get', {
        classification_id: 32
      }).then(res => {
        this.slideImgList = res.data
        this.$nextTick(() => {
          this.setSwiper()
        })
      })
    },
    showImgViewer (data) {
      this.$refs.refMaxOpen.open(data)

      // this.imgViewerList = url
      // this.imgViewerVisible = true
      // const m = (e) => {
      //   e.preventDefault()
      // }
      // document.body.style.overflow = 'hidden'
      // document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    closeImgViewer () {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    }
  }
}
</script>

<style  lang="less">
.manuscript1 {
  width: 100vw;
  height: 100vh;
  background: url("../components/image/hongzhua/hongzhua_bg.png") no-repeat center center #5c5c5c;

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: #A8A8A8;
    }
    .swiper-container-horizontal > .swiper-pagination-progressbar {
      position: absolute;
      width: calc(100vw * 0.65);
      top: -52px;
      height: 2px;
      left: 30%;
    }
  .swiper-container {
    width: 100%;
    height: 77vh;
    overflow: inherit;
    position: relative;
  }
  .header {
    // width: 74%;
    // height: 23vh;
    // margin: 0 auto;
    width: 100%;
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    padding: 30px 0;
    .nav_title {
      width: 500px;
      .nav_title_group {
        width: 500px;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        padding-right: 100px;
        >span {
          display: block;
          font-size: 45px;
          font-weight: 600;
          color: #fff;
          padding-left: 50px;
        }
        >p {
          font-size: 37px;
          font-weight: 600;
          color: #B4DBCF;
          position: absolute;
          left: 200px;
          margin-top: 100px;
          // padding-left: 50px;
        }
        &::before {
          content: " ";
          width: 150px;
          height: 150px;
          background: #B4DBCF;
        }
      }
      // padding-left: 140px;
      // span {
      //   font-size: 24px;
      //   font-weight: 600;
      //   color: #ffffff;
      // }
      // p {
      //   font-size: 36px;
      //   font-weight: 600;
      //   color: #ffffff;
      // }
    }
    .pagination_group {
      display: flex;
      .pagination {
        width: calc(100vw * 0.50);
      }
      .btn_group {
        display: flex;
        img {
          width: 60px;
          height: 60px;
          display: block;
          margin-left: 30px;
          transition: all .3s;
          &:hover {
            cursor: pointer;
            opacity: .55;
          }
          &:active {
            position: relative;
            top:1px;
          }
        }
      }
    }
  }
  .swiper-slide {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 45px;
    .swiper_slide_img {
      position: relative;
      display: flex;
      align-items: center;
      &:hover {
        .serach_img {
          display: block;
          cursor: pointer;
        }
      }
    }
    > p {
      padding: 21px 0 60px 0;
      font-size: 21px;
      font-weight: 500;
      color: #ffffff;
    }
    .serach_img {
      position: absolute;
      width: 80px;
      height: 80px;
      left: 50%;
      top: 50%;
      margin-left: -40px;
      margin-top: -40px;
      display: none;
      z-index: 99999;
    }
  }

  .swiper-slide .swiper_slide_img_pr {
    display: block;
    width: 400px;
    max-height: 600px;
    object-fit: cover;
    position: relative;
  }
}
</style>
