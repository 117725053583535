<template>
  <div class="public_max4">
    <div class="public_max_right">
      <img data-swiper-parallax="-20" data-swiper-parallax-opacity="0.5" data-swiper-parallax-scale="1.05"  data-swiper-parallax-duration="1200" src="./image/max/max3.png" alt="" srcset="">
    </div>
    <div class="public_max_left">
      <div class="public_max_left_title">
        <div class="public_max_left_h1">
          <span class="">花踪叶影</span>
          <!-- 回归 -->
          <!-- <p class=""> </p> -->
        </div>
        <div class="public_max_left_box ">
        </div>
      </div>
      <div class="text_info">
        <span  data-swiper-parallax="-150"  data-swiper-parallax-duration="600" >- 04 -</span>
        <h2 data-swiper-parallax="-200"  data-swiper-parallax-duration="800">只今依旧识徐黄</h2>
        <p data-swiper-parallax="-300"  data-swiper-parallax-duration="1100">这一时期的作品摈弃了标志性的泼彩背景与主体 物象相融合的手法，用色更清淡古朴，更强调线 条的写意性，笔墨之间流露出更多深层次的思想 情感及人文关怀。</p>
        <div  data-swiper-parallax="-400"   data-swiper-parallax-duration="1200"  @click="handleInfoById({id:5,name:'花踪叶影',label:'回归',info:'只今依旧识徐黄'})" class="btn_box btn_hover ">
          <span>全部作品</span>
          <img src="./image/btn_r.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 跳转到详情
     */
    handleInfoById (query) {
      this.$emit('goInfoById', query)
      // this.$router.push({ path: '/list', query: query })
    }
  }
}
</script>

<style lang="less">
  .public_max4 {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: url("../components/image/max/max3_bg.png") no-repeat bottom right #fff;
    .text_info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding-top: 9.5%;
      >span {
        font-size: 27px;
        font-weight: 500;
        color: #EAC06B;
        display: block;
        width: 100%;
        text-align: center;
      }
      >h2 {
        font-size: 39px;
        font-weight: 600;
        color: #5C5C5C;
        line-height: 140px;
      }
      >p {
        font-size: 21px;
        font-weight: 500;
        color: #5C5C5C;
        line-height: 42px;
        padding: 0 20%;
        text-align: center;
      }
      // flex-direction: column;
    }
    .btn_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 357px;
      height: 66px;
      background: #5C5C5C;
      position: relative;
      margin-top: 13%;
      span {
        font-size: 24px;
        font-weight: normal;
        color: #FFFFFF;
      }
      img {
        transition: all .3s;
        position: absolute;
        right: 22px;
        width: 42px;
        height: 21px;
      }
    }
    .public_max_left_title {
      display: flex;
      height: 140px;
      margin-top: 6.5%;
      justify-content: flex-end;
      overflow: hidden;
      .public_max_left_box {
        width: 140px;
        height: 140px;
        background: #EAC06B;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .public_max_left_h1 {
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        height: 140px;
        overflow: hidden;
        padding-right: 5%;
        // align-items: center;
        span {
          font-size: 48px;
          font-weight: 600;
          color: #5C5C5C;
        }
        p{
            font-size: 39px;
            font-weight: 600;
            color: #EAC06B;
            position: relative;
            &::before {
              content: ' 1';
              width: 28px;
              height: 60px;
              background: url('./image/line_orange.png') no-repeat;
              background-size: 100% 100%;
              color: transparent;
            }
        }
      }
    }
    .public_max_left {
       width: 35%;
       height: 100vh;
      //  flex: 1;
      //  display: flex;
      //  flex-wrap: wrap;
    }
    .public_max_right {
      width: 65%;
      height: 100vh;
      >img{
          height: 100%;
          width: 100%;
          object-fit: cover;
      }
    }
  }
</style>
