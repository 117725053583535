<template>
  <transition name="el-fade-in-linear">
    <div v-show="showMenu" class="transition-box" @click.stop="()=>{}">
      <ul class="nav-list">
        <li v-for="(item,index) in menuList" :key="index" @click="toPage(item)"><span>{{item.name}}</span>
           <div class="menu_item_list" v-if="item.list">
              <div v-for="val in item.list" :key="val.name" class="menu_item_text" @click.stop="toPage(val)">
                {{val.name}}
              </div>
            </div>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { on, off } from '@/utils/event.js'
export default {
  name: 'MenuTemp',
  props: {
    // menuList: {
    //   required: true,
    //   type: Array
    // }
    type: {
      default: 1
    }
  },
  data () {
    return {
      showMenu: false,
      menuList: [
        {
          name: '首页',
          page: 0
        }, {
          name: '策展人说',
          page: 1
        }, {
          name: '花踪叶影',
          page: 3,
          list: [{
            name: '/雕虫筑鸟老瓷工',
            page: 3,
            index: 0
          }, {
            name: '/我爱丹青足陶然',
            page: 3,
            index: 1
          }, {
            name: '/宋人檐外求生活',
            page: 3,
            index: 2
          }, {
            name: '/只今依旧识徐黄',
            page: 3,
            index: 3
          }]
        }, {
          name: '雪泥鸿爪',
          page: 4,
          list: [{
            name: '/千载谁是能书人',
            page: 4
          }, {
            name: '/工写勾泼任翕张',
            page: 5
          }, {
            name: '/案有笔砚共黄昏',
            page: 6
          }]
        }, {
          name: '艺术年表',
          page: 7
        }, {
          name: '捐赠收藏',
          page: 8
        }, {
          name: '文创天地',
          page: 9
        }, {
          name: '实景漫游',
          page: 9
        }
      ]

    }
  },
  watch: {
    showMenu (data) {
      this.$emit('input', data)
    }
  },
  mounted () {
    on(document.body, 'click', this.handleClick)
  },
  beforeDestroy () {
    off(document.body, 'click', this.handleClick)
  },
  methods: {
    handleClick () {
      this.showMenu && (this.showMenu = false)
    },
    changeShowMenu () {
      this.showMenu = !this.showMenu
    },
    toPage (item) {
      console.log(item)
      if (this.type === 1) {
        this.$emit('toPage', item)
        this.changeShowMenu()
      } else {
        this.$router.push({
          path: '/',
          query: {
            page: item.page,
            index: item.index >= 0 ? item.index : -1
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.transition-box {
  position: fixed;
  min-width:250px;
  height: calc(100vh - 65px);
  background-color: rgba(50, 81, 88, .83);
  left:0;
  bottom: 0;
  z-index: 99999999999;
  .menu_item_list .menu_item_text {
    line-height:40px;
    font-size: 14px;
    padding-left: 10px;
    &:last-child{
      padding-bottom: 20px;
    }
  }
  .nav-list{
    padding: 20px 48px 0;
    li{
      font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, .9);
    line-height: 60px;
    font-weight: normal;
    cursor: pointer;
    span{
    //    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    }
    line-height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    }
  }
}
</style>
