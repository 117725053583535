import axios from 'axios'
const saveService = axios.create({
  baseURL: 'http://192.168.140.77:9999',
  timeout: 15000
})
// const local = localStorage.getItem('lacal')
// window.localData = local ? JSON.parse(local) : {}
// console.log('window.localData', window.localData)
export const getSaveUrl = (url, method, params) => {
  let paramsStr = `${url}-${method}`
  for (const key in params) {
    paramsStr += `-${key}-${params[key]}`
  }
  return paramsStr
}
export const saveHandle = (response) => {
  let { url, params, method } = response.config
  params = params || {}
  const paramsStr = getSaveUrl(url, method, params)
  // window.localData[paramsStr] = response.data
  //   console.log('数据返回')
  //   console.log('url', url)
  //   console.log('params', params)
  //   console.log(window.localData)
  saveLocal(paramsStr, response.data)
}
export const saveLocal = (name, value) => {
  //   window.localStorage.setItem('lacal', saveStr)
  console.log('怎么还是没有数据呀', name)
//   const saveStr = JSON.stringify(window.localData)
//   saveService.post('/save', {
//     name,
//     value
//   })
}
