<template>
  <div class="public_max public_list">
    <div class="public_max_right">
      <!-- :src="huaData[index].url" -->
      <img data-swiper-parallax="-20" data-swiper-parallax-opacity="0.5"  data-swiper-parallax-scale="1.05" data-swiper-parallax-duration="1200" src="../components/image/max/max1.png" alt="" srcset="">
    </div>
    <div class="public_max_left">
      <div class="public_max_left_title">
        <div class="public_max_left_h1">
          <span class="">花踪叶影</span>
          <!-- 深入 -->
          <!-- <p class=""> </p> -->
        </div>
        <div class="public_max_left_box ">
        </div>
      </div>
      <div  class="text_info">
        <span  data-swiper-parallax="-150"  data-swiper-parallax-duration="600" class="">- 02 -</span>
        <h2  data-swiper-parallax="-200"  data-swiper-parallax-duration="800" class="" data-wow-delay=".3s">我爱丹青足陶然</h2>
        <p   data-swiper-parallax="-300"  data-swiper-parallax-duration="1100" class="" data-wow-delay=".5s">对花鸟鱼虫了进行大量写生、领会了“以形写神” 的精髓，同时在执教期间对工笔画有了更深的领 悟。</p>
        <div   data-swiper-parallax="-400"   data-swiper-parallax-duration="1200" @click="handleInfoById({id:3,name:'花踪叶影',label:'深入',info:'我爱丹青足陶然'})" class="btn_box btn_hover ">
          <span>全部作品</span>
          <img src="./image/btn_r.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
import huaData from '../components/data/hua.js'
export default {
  data () {
    return { huaData: huaData, index: 53, timer: null }
  },
  methods: {
    /**
     * 跳转到详情
     */
    handleInfoById (query) {
      this.$emit('goInfoById', query)
    },
    getSwiperAn () {
      // this.index = 0
      // clearInterval(this.timer)
      // this.timer = setInterval(() => {
      //   if (this.index < 53) {
      //     ++this.index
      //     $('#public_max_right_img').attr('src', this.huaData[this.index].url)
      //   } else {
      //     clearInterval(this.timer)
      //   }
      // }, 200)
    }
  },
  mounted () {
    // this.getSwiperAn()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less">
  .public_max {
    width: 100vw;
    height: 100vh;
    display: flex;
    background: #fff;
    position: relative;
    overflow: hidden;
    &:hover{
    }
    .text_info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding-top: 9.5%;
      >span {
        font-size: 27px;
        font-weight: 500;
        color: #EAC06B;
        display: block;
        width: 100%;
        text-align: center;
      }
      >h2 {
        font-size: 39px;
        font-weight: 600;
        color: #5C5C5C;
        line-height: 140px;
      }
      >p {
        font-size: 21px;
        font-weight: 500;
        color: #5C5C5C;
        line-height: 42px;
        padding: 0 20%;
        text-align: center;
      }
      // flex-direction: column;
    }
    .btn_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 357px;
      height: 66px;
      background: #5C5C5C;
      position: relative;
      margin-top: 13%;
      &:hover {
        cursor: pointer;
        opacity: .75;
      }
      span {
        font-size: 24px;
        font-weight: normal;
        color: #FFFFFF;
      }
      img {
        transition: all .3s;
        position: absolute;
        right: 22px;
        width: 42px;
        height: 21px;
      }
    }
    .public_max_left_title {
      display: flex;
      height: 140px;
      justify-content: flex-end;
      padding-top: 6.5%;
      .public_max_left_box {
        width: 140px;
        height: 140px;
        background: #EAC06B;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .public_max_left_h1 {
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        height: 140px;
        overflow: hidden;
        padding-right: 5%;
        // align-items: center;
        span {
          font-size: 48px;
          font-weight: 600;
          color: #5C5C5C;
        }
        p{
            font-size: 39px;
            font-weight: 600;
            color: #EAC06B;
            position: relative;
            &::before {
              content: ' 1';
              width: 28px;
              height: 60px;
              background: url('./image/line_orange.png') no-repeat;
              background-size: 100% 100%;
              color: transparent;
            }
        }
      }
    }
    .public_max_left {
       width: 35%;
       height: 100vh;
      //  flex: 1;
      //  display: flex;
      //  flex-wrap: wrap;
    }
    .public_max_right {
      width: 65%;
      height: 100vh;
      position: relative;
      >img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
          //  object-fit:scale-down;
      }
    }
  }
</style>
