const hua = [
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00039.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00040.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00041.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00042.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00043.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00044.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00045.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00046.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00047.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00048.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00049.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00050.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00051.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00052.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00053.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00054.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00055.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00056.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00057.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00058.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00059.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00060.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00061.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00062.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00063.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00064.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00065.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00066.png')
  }, {
    url: require('../../../assets/hua/Stop_War_HD 2_00067.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00068.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00069.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00070.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00071.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00072.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00073.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00074.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00075.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00076.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00077.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00078.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00079.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00080.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00081.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00082.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00083.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00084.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00085.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00086.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00087.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00088.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00089.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00090.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00091.png')
  },
  {
    url: require('../../../assets/hua/Stop_War_HD 2_00092.png')
  }
]
export default hua
