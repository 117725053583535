/* eslint-disable no-unused-vars */
import axios from 'axios'
import { Message } from 'element-ui'
import { saveHandle, getSaveUrl } from '@/utils/save.js'
// import { localData } from '@/utils/localData'
// window.localData = {}
axios.defaults.timeout = 15000
axios.defaults.withCredentials = false
// eslint-disable-next-line no-unused-vars
const service = axios.create({
  // baseURL: 'https://artdata-mini-site.artron.net', // url = base url + request url
  baseURL: window.location.origin === 'http://localhost:8080' ? 'http://zouchuanan-h5.hnmuseum.artronartdata.com' : window.location.origin,
  timeout: 15000
  // withCredentials: true // send cookies when cross-domain requests
})

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
}
// service.interceptors.request.use(
//   (config) => {
//
//   },
//   (error) => {
//     Promise.reject(error)
//   }
// )
service.interceptors.response.use(
  (response) => {
    // saveHandle(response)
    const res = response.data
    if (res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return response.data.data || response.data
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

/**
 * axios请求
 * @param url 请求地址
 * @param method {METHOD} http method
 * @param params 请求参数
 * @returns {Promise<AxiosResponse<T>>}
 */
async function request (url, method, params, config) {
  // console.log('数据请求')
  // console.log('url', url)
  // console.log('method', method)
  // console.log('params', params)
  // const localDataUrl = getSaveUrl(url, method, params)

  // if (localData[localDataUrl]) {
  //   return Promise.resolve(localData[localDataUrl].data)
  // }
  // return
  // eslint-disable-next-line no-unreachable
  switch (method) {
    case METHOD.GET:
      return service.get(url, { params, ...config })
    case METHOD.POST:
      return service.post(url, params, config)
    case METHOD.PUT:
      return service.put(url, params, config)
    case METHOD.PATCH:
      return service.patch(url, params, config)
    case METHOD.DELETE:
      return service.delete(url, { params, ...config })
    default:
      return service.get(url, { params, ...config })
  }
}

export {
  METHOD,
  request
}
