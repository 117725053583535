<template>
  <div class="c_video">
    <div   data-aos="fade-up"  data-aos-duration="1600" class="video_box " id="page2_an_1">
      <video ref="videoPlayer" class="video" controls>
        <source :src="`${videoUrl}/ftp/suiyueyugao.mp4`" type="video/mp4">
      </video>
      <p data-aos="fade-up" class="video_p">《岁月——邹传安先生的艺术人生》专题片</p>
      <div class="video_play" v-if="isVideo" @click="toPay">
          <img src="./image/video_play.png" alt="" srcset="">
      </div>
    </div>
      <div data-aos="fade-up" id="page2_an_2"  data-aos-duration="800"  class="box1 puBox  fadeInUp fadeIn"></div>
      <div data-aos="fade-up" id="page2_an_3" data-aos-duration="600"  class="box2 puBox  fadeInUp fadeIn"></div>
      <div data-aos="fade-up" id="page2_an_4"  data-aos-duration="1600" class="box3 puBox  fadeInUp fadeIn"></div>
  </div>
</template>

<script>
import { videoUrl } from '@/config/index.js'
export default {
  data () {
    return {
      isVideo: true,
      videoUrl
    }
  },
  methods: {
    toPay () {
      console.debug('this.$refs.videoPlayer')
      console.debug(this.$refs.videoPlayer)
      this.isVideo = false
      this.$refs.videoPlayer.paused ? this.$refs.videoPlayer.play() : this.$refs.videoPlayer.pause()
    }
  }
}
</script>

<style lang="less">
  .c_video {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #F4F3F1;
    overflow: hidden;
    .puBox {
      width: 150px;
      height: 270px;
    }
    .box1 {
      background: #B4DBCF;
      position: absolute;
      left: 790px;
      margin-top: 226px;
      z-index: 10;
      // bottom: -200px;
    }
    .box2 {
      background: #EAC06B;
      left: 150px;
      // bottom: -200px;
      position: absolute;
      bottom: 0;
    }
    .box3 {
      background: #EAC06B;
      right: 320px;
      top: -160px;
      position: absolute;
    }
    .video_box {
      width: 854px;
      height: 480px;
      position: absolute;
      // padding: 0 160px;
      top: 226px;
      z-index: 1000;
      right: 150px;
      .video {
        width: 100%;
        height: 100%;
        // background: #F4F3F1;
      }
      .video_p {
        position: absolute;
        bottom: -40px;
        right: 0px;
        font-size: 21px;
        font-weight: 500;
        color: #5C5C5C;
      }
      .video_play {
        position: absolute;
        width: 854px;
        height: 480px;
        top: 0px;
        left: 0px;
        background: url('./image/video01.png') no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        >img {
          width: 78px;
          height: 78px;
          cursor: pointer;
          &:hover{
            position: relative;
            opacity: .75;
          }
          &:active {
            position: relative;
            top:1px;
          }
        }
      }
    }
  }
</style>
