<template>
  <div class="public_max0">
    <div class="public_max_left">
      <div class="public_max_left_title">
        <div data-aos=" fade-up"  class="public_max_left_box wow fadeInUp fadeIn"></div>
        <div data-aos=" fade-up" class="public_max_left_h1">
          <span data-aos="fade-up" class="wow fadeInUp fadeIn">花踪叶影</span>
          <!-- 浅入 -->
          <!-- <p data-aos="fade-up" data-aos-duration="1000" class="wow fadeInUp fadeIn"></p> -->
        </div>
      </div>
      <div class="text_info">
        <span data-aos="fade-up" class="wow fadeInUp fadeIn" >- 01 -</span>
        <h2 data-aos="fade-up" class="wow fadeInUp fadeIn" data-aos-duration="300">雕虫筑鸟老瓷工</h2>
        <p data-aos="fade-up" class="wow fadeInUp fadeIn" data-aos-duration="500">瓷工期间练就了精湛的基本功，有许多<br/>优秀实物 瓷器作品以及手稿。</p>
        <div data-aos="fade-up" data-aos-duration="600" @click="handleInfoById({id:2,name:'花踪叶影',label:'浅入',info:'雕虫筑鸟老瓷工'})"  class="btn_box btn_hover wow fadeInUp fadeIn">
          <span>全部作品</span>
          <img src="./image/btn_r.png" alt="" />
        </div>
      </div>
    </div>
    <div class="public_max_right">
      <img data-aos="fade-up" class="wow fadeInUp fadeIn" data-aos-duration="500" src="./image/max/max0_1.png" alt="" srcset="" />
      <img data-aos="fade-up" class="wow fadeInUp fadeIn" data-aos-duration="1000" src="./image/max/max0_2.png" alt="" srcset="" />
      <img data-aos="fade-up" class="wow fadeInUp fadeIn" data-aos-duration="1400" src="./image/max/max0_3.png" alt="" srcset="" />
      <img data-aos="fade-up" class="wow fadeInUp fadeIn" data-aos-duration="1400" src="./image/max/max0_4.png" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 跳转到详情
     */
    handleInfoById (query) {
      this.$emit('goInfoById', query)
      // this.$router.push({ path: '/list', query: query })
    }
  }
}
</script>

<style lang="less">
.public_max0 {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #fff;
  overflow: hidden;
  .text_info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-top: 9.5%;
    > span {
      font-size: 27px;
      font-weight: 500;
      color: #eac06b;
      display: block;
      width: 100%;
      text-align: center;
    }
    > h2 {
      font-size: 39px;
      font-weight: 600;
      color: #5c5c5c;
      line-height: 140px;
    }
    > p {
      font-size: 21px;
      font-weight: 500;
      color: #5c5c5c;
      line-height: 42px;
      padding: 0 20%;
      text-align: center;
    }
    // flex-direction: column;
  }
  .btn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 357px;
    height: 66px;
    background: #5c5c5c;
    position: relative;
    margin-top: 13%;
    span {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
    }
    img {
      transition: all .3s;
      position: absolute;
      right: 22px;
      width: 42px;
      height: 21px;
    }
  }
  .public_max_left_title {
    display: flex;
    height: 140px;
    padding-top: 6.5%;
    .public_max_left_box {
      width: 140px;
      height: 140px;
      background: #eac06b;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .public_max_left_h1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 140px;
      overflow: hidden;
      padding-left: 5%;
      // align-items: center;
      span {
        font-size: 48px;
        font-weight: 600;
        color: #5c5c5c;
      }
      p {
        font-size: 39px;
        font-weight: 600;
        color: #eac06b;
        position: relative;
        &::before {
          content: " 1";
          width: 28px;
          height: 60px;
          background: url("./image/line_orange.png") no-repeat;
          background-size: 100% 100%;
          color: transparent;
        }
      }
    }
  }
  .public_max_left {
    width: 50%;
    height: 100vh;
    //  flex: 1;
    //  display: flex;
    //  flex-wrap: wrap;
  }
  .public_max_right {
    width: 50%;
    height: 100vh;
    position: relative;
    > img {
      // object-fit: cover;
      &:nth-child(1) {
        width: 580px;
        height: 570px;
        position: absolute;
        top: 2%;
        left: -80px;
        z-index: 22;
      }
      &:nth-child(2) {
        width: 415px;
        height: 434px;
        top: 2%;
        right: 12%;
        position: absolute;
      }
      &:nth-child(3) {
        width: 330px;
        height: 319px;
        position: absolute;
        left: 50%;
        margin-left: -230px;
        top: 50%;
        z-index: 33;
      }
       &:nth-child(4) {
        width: 823px;
        height: 780px;
        position: absolute;
        right: -35%;
        top: 0%;
        z-index: 33;
      }
    }
  }
}
</style>
