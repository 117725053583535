<template>
  <div class="home_hander">
    <div class="grid_container">
      <div class="grid_container_item item1">
        <div class="grid_container_item_image_box">
          <img
            class="grid_container_item_image  animated animation1 duration_2"
            :src="menuData[0].url"
            alt=""
            srcset=""
          />
          <img
            class="grid_container_item_image  animated animation1 duration_2"
            :src="menuData[0].colorUrl"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="grid_container_item item2">
        <div class="item2_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image  animated animation1 duration_2"
              :src="menuData[1].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image  animated animation1 duration_2"
              :src="menuData[1].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item2_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[2].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[2].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item2_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated animation1 delay2 duration_2"
              :src="menuData[3].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[3].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item2_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[4].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[4].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item2_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[5].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[5].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div class="grid_container_item item3">
        <div class="grid_container_item_image_box">
          <img
            class="grid_container_item_image animated zoomIn"
            :src="menuData[6].url"
            alt=""
            srcset=""
          />
          <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[6].colorUrl"
              alt=""
              srcset=""
            />
        </div>
      </div>
      <div class="grid_container_item item4">
        <div class="item4_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[7].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[7].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item4_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[8].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[8].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item4_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated animation1 duration_1 delay_5"
              :src="menuData[9].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[9].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div class="grid_container_item item5">
        <div class="item5_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated animated zoomIn"
              :src="menuData[10].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[10].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item5_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[11].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[11].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item5_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[12].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[12].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item5_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated animation1 delay1 duration_2"
              :src="menuData[13].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[13].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div class="grid_container_item item6">
        <div class="item6_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[14].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[14].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item6_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[15].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[15].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item6_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[16].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[16].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div class="grid_container_item item7">
        <div class="item7_group">
          <div class="flex_box">
            <span class="animated fadeInLeft delay6 duration_4">花魂鸟魄</span>
            <p class="animated fadeInUp delay6 duration_5">邹传安·艺术与文献展</p>
          </div>
          <!-- <img class="animated rotateInDownRight delay6 duration_4" src="./image/line.png" alt="" /> -->
        </div>
      </div>
      <div class="grid_container_item  item8 ">
        <div class="grid_container_item_image_box">
          <img
            class="grid_container_item_image animated animation1 duration_2 delay_1"
            :src="menuData[17].url"
            alt=""
            srcset=""
          />
          <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[17].colorUrl"
              alt=""
              srcset=""
            />
        </div>
      </div>
      <div class="grid_container_item item9">
        <div class="item9_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[18].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[18].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item9_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[19].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[19].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item9_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[20].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[20].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item9_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[21].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[21].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="item9_grid">
          <div class="grid_container_item_image_box">
            <img
              class="grid_container_item_image animated zoomIn"
              :src="menuData[22].url"
              alt=""
              srcset=""
            />
            <img
              class="grid_container_item_image animated animation1 delay3 duration_2"
              :src="menuData[22].colorUrl"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <!-- <div class="grid_container_item item10">10</div>
      <div class="grid_container_item item11">11</div> -->
    </div>
    <div @click="toNext" class="page_next toHanimation">
      <img src="../../assets/page_next.png" alt="">
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { menuData } from './data/menu'

export default {
  data () {
    return {
      menuData
    }
  },
  methods: {
    toNext () {
      this.$emit('toNext')
    }
    // setAnmin() {},
  },
  components: {},
  mounted () {
    // console.log('menuData...', this.menuData);
    // const This = this
    let index = -1
    setTimeout(() => {
      $('#content_item1 .grid_container_item_image').removeClass('animation1 duration_1 zoomIn')
      function run () {
        if (index < 22) {
          setTimeout(() => {
            index++
            // console.log(index);
            $('.home_hander .grid_container_item_image_box .grid_container_item_image:nth-child(1)').eq(index).fadeOut(1000)
            $('.home_hander .grid_container_item_image_box .grid_container_item_image:nth-child(2)').eq(index).fadeIn(1000)
            run()
          }, 100 + index)
        }
      }
      run()
    }, 2600)
    $('img').attr({ oncontextmenu: 'return false;', ondragstart: 'return false;' })
  }
}
</script>

<style lang="less">
@keyframes toH {
  0% {
    opacity: 0;
    bottom: 10px;
  }
  100% {
    opacity: 1;
    bottom: 30px;
  }
}
.toHanimation{
  animation-name: toH; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
  animation-duration: 1s; // 动画完成时间
}
@height:100vh /5;
@red: #fff;
.home_hander {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .page_next {
    cursor: pointer;
    transform: rotate(180deg);
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 1000000;
    img {
      width: 40px;
      height: 40px;
    }
  }
  .imgS {
    transform: scale(1);
  }
  .grid_container_item_image_box {
    height: 100%;
    width: 100%;
    position: relative;
    .grid_container_item_image {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      background: #f5f5f5;
      z-index: 10;
      &:nth-child(2){
        z-index: 11;
        display: none;
      }
    }
  }
  .grid_container {
    .item1 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      height: calc(@height*2 + 2px);
    }
    .item2 {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 3;
      // 第二等级网格布局
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 2px;
      .item2_grid {
        background-color: @red;
        height: calc(@height);
        &:nth-child(4) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
    .item3 {
      grid-column-start: 6;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-gap: 2px;
    }
    .item4 {
      grid-column-start: 7;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 4;
      // 第二等级网格布局
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 2px;
      .item4_grid {
        background-color: @red;
        height: calc(@height);
        &:nth-child(3) {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 4;
          height: calc(@height*2 + 2px);
        }
      }
    }
    .item5 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 6;
      // 第二等级网格布局
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 2px;
      .item5_grid {
        background-color: @red;
        height: calc(@height);
        &:nth-child(2) {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;
          height: calc(@height*2 + 2px);
        }
        &:nth-child(4) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
    .item6 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 3;
      grid-row-end: 6;
      // 第二等级网格布局
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 2px;
      .item6_grid {
        height: calc(@height);
        background-color: @red;
      }
    }
    .item7 {
      grid-column-start: 4;
      grid-column-end: 7;
      grid-row-start: 3;
      grid-row-end: 4;
      position: relative;
      display: flex;
      height: calc(@height);
      background: #f4f3f1;
      grid-gap: 2px;
      .item7_group {
        display: flex;
        justify-items: center;
        align-items: center;
        padding: 0 20px;
        background: #F4F3F1;
      }
      // padding: 36px 48px;
      // padding: 1em;
      // @media (max-width:1880px){
      //   .flex_box {
      //      span {
      //     display: block;
      //     font-size:2.6em;
      //     font-weight: 600;
      //     color: #2E2E2E;
      //     letter-spacing: 1em;
      //       // padding-top: 1em;
      //     }
      //     p {
      //       width: 100%;
      //       font-size: 1.9em;
      //       font-weight: 400;
      //       color: #5C5C5C;
      //       letter-spacing: 0.7em;
      //       // padding-bottom: 1em;
      //     }
      //   }
      // }
      //  @media (max-width:1600px){
      //   .flex_box {
      //      span {
      //     display: block;
      //     font-size:2.4em;
      //     font-weight: 600;
      //     color: #2E2E2E;
      //     letter-spacing: 1em;
      //       // padding-top: 1em;
      //     }
      //     p {
      //       width: 100%;
      //       font-size: 1.6em;
      //       font-weight: 400;
      //       color: #5C5C5C;
      //       letter-spacing: 0.7em;
      //       // padding-bottom: 1em;
      //     }
      //   }
      // }
      //  @media (max-width:1370px){
      //   .flex_box {
      //      span {
      //     display: block;
      //     font-size:2.0em;
      //     font-weight: 600;
      //     color: #2E2E2E;
      //     letter-spacing: .7em;
      //       // padding-top: 1em;
      //     }
      //     p {
      //       width: 100%;
      //       font-size: 1.4em;
      //       font-weight: 400;
      //       color: #5C5C5C;
      //       letter-spacing: 0.5em;
      //       // padding-bottom: 1em;
      //     }
      //   }
      // }
      //  @media (max-width:830px){
      //   .flex_box {
      //      span {
      //     display: block;
      //     font-size:1.4em;
      //     font-weight: 600;
      //     color: #2E2E2E;
      //     letter-spacing: .7em;
      //       // padding-top: 1em;
      //     }
      //     p {
      //       width: 100%;
      //       font-size: 1em;
      //       font-weight: 400;
      //       color: #5C5C5C;
      //       letter-spacing: 0.5em;
      //       // padding-bottom: 1em;
      //     }
      //   }
      // }
      .flex_box {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        // padding: 1em 0;
      }
      span {
        display: block;
        font-size: 63px;
        font-weight: 600;
        color: #2e2e2e;
        margin-top: -10px;
        // letter-spacing: 30px;
        // padding-top: 1em;
      }
      p {
        width: 100%;
        font-size: 46px;
        font-weight: 400;
        color: #5c5c5c;
        letter-spacing: 16px;
        // letter-spacing: 1.0em;
        // padding-bottom: 1em;
      }
      img {
        width: 42px;
        height: 70%;
        // height: 152px;
        display: block;
      }
    }
    .item8 {
      grid-column-start: 4;
      grid-column-end: 6;
      grid-row-start: 4;
      grid-row-end: 6;
      grid-gap: 2px;
    }
    .item9 {
      grid-column-start: 6;
      grid-column-end: 9;
      grid-row-start: 4;
      grid-row-end: 6;
      // 第二等级网格布局
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 2px;
      .item9_grid {
        background-color: @red;
        height: calc(@height);
        &:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: calc(@height) calc(@height) calc(@height) calc(@height) calc(
        @height
      );
    grid-gap: 2px;
    box-sizing: border-box;
  }
  .grid_container > div {
    // background-color: rgba(255, 255, 255, 0.8);
    // text-align: center;
    // padding: 20px 0;
    // font-size: 30px;
  }
  // .nav_1 {
  //   width: calc(@wdith*6);
  //   height: calc(@height*2);
  // }
  // .keyframes(all,zindexName,{
  //     from{scale: 1.15;}
  //     to{scale: 1}
  // });
}
// .keyframes (@prefix,@name,@content) when (@prefix=def) {
//   @keyframes @name {
//     @content();
//   }
// }
// .keyframes (@prefix,@name,@content) when (@prefix=moz) {
//   @-moz-keyframes @name {
//     @content();
//   }
// }
// .keyframes (@prefix,@name,@content) when (@prefix=o) {
//   @-o-keyframes @name {
//     @content();
//   }
// }
// .keyframes (@prefix,@name,@content) when (@prefix=webkit) {
//   @-webkit-keyframes @name {
//     @content();
//   }
// }
// .keyframes (@prefix,@name,@content) when (@prefix=all) {
//   .keyframes(moz,@name,@content);
//   .keyframes(o,@name,@content);
//   .keyframes(webkit,@name,@content);
//   .keyframes(def,@name,@content);
// }
</style>
