<template>
  <div class="index_footer">
    <div class="index_footer_group" v-if="show">
      <div class="index_footer_left">
        <span  class="wow fadeInUp fadeIn">- Cultural creation -</span>
        <p  class="wow fadeInUp fadeIn">文创天地</p>
        <div @click="handleCultural" class="btn_right  wow fadeInUp fadeIn">
          <img src="./image/btn_r.png" alt="" srcset="" />
        </div>
      </div>
      <div class="index_footer_right">
        <span  class="wow fadeInUp fadeIn">- VR roaming -</span>
        <p  class="wow fadeInUp fadeIn">实景漫游</p>
        <div @click="handle_AR" class="btn_right  wow fadeInUp fadeIn">
          <img src="./image/btn_r.png" alt="" srcset="" />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_left">
        <p>湘ICP备14005632号 湖南省博物馆 版权所有</p>
      </div>
      <div @click="goTop" class="footer_right">
        <img src="./image/toTop.png" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      default: true
    }
  },
  methods: {
    goTop () {
      this.$emit('goTop')
    },
    handle_click () {
      this.$alert('正在建设中...', '', {
        confirmButtonText: '确定'
      })
    },
    // 文创天地跳转
    handleCultural () {
      this.$router.push({
        path: '/culturalcreationdetail'
      })
      // this.$alert('正在建设中...', '', {
      //   confirmButtonText: '确定'
      // })
    },
    // 实景漫游
    handle_AR () {
      window.location.href = 'http://quanjing.artron.net/scene/2s6cRFEwr3qiyvUHybWuUmEQSdcl2Uey/20220523-hhnp/tour.html'
    }
  }
}
</script>

<style lang="less">
.index_footer {
  width: 100vw;
  // height: 480px;
  .footer {
    // width: 100vw;
    padding: 0 120px;
    height: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .footer_right {
      cursor: pointer;
      img {
        width: 21px;
        height: 42px;
      }
    }
    .footer_left {
      > span {
        font-size: 18px;
        font-weight: 400;
        color: #5c5c5c;
        line-height: 45px;
      }
      > p {
        font-size: 18px;
        font-weight: 400;
        color: #5c5c5c;
        line-height: 45px;
      }
    }
  }
  .index_footer_left {
    background: url("./image/footer/left_img.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 27px;
      font-weight: 600;
      color: #ffffff;
    }
    > p {
      font-size: 48px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 75px;
    }
    .btn_right {
      width: 87px;
      height: 66px;
      background: #5c5c5c;
      display: flex;
      align-items: center;
      justify-items: center;
      transition: all 1s;
        &:hover {
          cursor: pointer;
          background: orange;
          img {
            transition: all .3s;
            width: 50px;
            padding-left: 14px;
          }
        }
      img {
        transition: all .3s;
        cursor: pointer;
        width: 42px;
        display: block;
        padding-left: 20px;
      }
    }
  }
  .index_footer_group {
    width: 100vw;
    height: 480px;
    display: flex;
  }
  .index_footer_left {
    width: 100%;
    height: 100%;
  }
  .index_footer_right {
    width: 100%;
    height: 100%;
    background: url("./image/footer/right_img.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 27px;
      font-weight: 600;
      color: #ffffff;
    }
    > p {
      font-size: 48px;
      font-weight: 600;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 75px;
    }
    .btn_right {
      width: 87px;
      height: 66px;
      background: #5c5c5c;
      display: flex;
      align-items: center;
      justify-items: center;
      transition: all 1s;
      &:hover {
          cursor: pointer;
          background: orange;
          img {
            transition: all .3s;
            width: 50px;
            padding-left: 14px;
          }
        }
      img {
        cursor: pointer;
        width: 42px;
        display: block;
        padding-left: 20px;
        transition: all 1s;
      }
    }
  }
}
</style>
