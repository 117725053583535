<template>
  <div class="footerItem02">
    <div class="public_max_left">
      <div class="text_info">
        <span data-aos="fade-up" class="wow fadeInUp fadeIn">- donation -</span>
        <h2 data-aos="fade-up" class="wow fadeInUp fadeIn">捐赠收藏</h2>
        <p data-aos="fade-up" class="wow fadeInUp fadeIn">
          本单元以“捐赠清单”和“专题访谈”聚焦邹传安先生对湖南省博物馆的捐赠事件。
        </p>
        <div data-aos="fade-up" @click="goSw" class="btn_box btn_hover wow fadeInUp fadeIn">
          <span>详情</span>
          <img src="./image/btn_r.png" alt="" />
        </div>
      </div>
    </div>
    <div class="public_max_right">
      <div data-aos="fade-up" class="video_box wow fadeInUp fadeIn">
        <video class="video" controls ref="videoPlayer">
          <source
            :src="`${videoUrl}/ftp/sanrenfangtan.mp4`"
            type="video/mp4"
          />
        </video>
        <p class="video_p wow fadeInUp fadeIn">《对话邹传安》</p>
        <div class="video_play wow fadeInUp fadeIn" v-if="isVideo" @click="toPay">
          <img src="./image/video_play.png" alt="" srcset="" />
        </div>
      </div>
      <!--  -->
      <!-- <ul class="hong_zhua_ul">
        <li class="wow fadeInUp fadeIn">
          <div class="div_title">
            <h4>1000多件</h4>
            <p>已惠赠</p>
          </div>
          <img src="./image/i1.png" alt="">
        </li>
        <li></li>
        <li class="wow fadeInUp fadeIn"> <img src="./image/i2.png" alt=""></li>
      </ul> -->
    </div>
  </div>
</template>

<script>
import { videoUrl } from '@/config/index.js'
export default {
  data () {
    return {
      isVideo: true,
      videoUrl
    }
  },
  methods: {
    goSw () {
      this.$router.push({ path: '/sw' })
    },
    toPay () {
      console.debug('this.$refs.videoPlayer')
      console.debug(this.$refs.videoPlayer)
      this.isVideo = false
      this.$refs.videoPlayer.paused ? this.$refs.videoPlayer.play() : this.$refs.videoPlayer.pause()
    }
  }
}
</script>

<style lang="less">
.footerItem02 {
  width: 100vw;
  height: 100vh;
  background: #f4f3f1;
  display: flex;
  .hong_zhua_ul {
      width: calc(100vw / 2);
      pointer-events: none;
      display: flex;
      padding-top: 78px;
      .div_title {
padding-bottom: 30px;
color: #5C5C5C;
      h4{font-size: 60px;}
      p{font-size: 21px;}
      }
      li {
        // border-left: 1px solid #ddd;
        width: calc(100vw / 3);
        height: 100%;
        box-sizing: border-box;
        &:nth-child(1){
          img{
            width:240px;
            height: 240px;
          }
        }
        &:nth-child(3){
          img{
            position: relative;
            left: -100px;
            width: 250px;
            height: 250px;
          }
        }
      }
    }
  .text_info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-top: 14.5%;
    > span {
      font-size: 27px;
      font-weight: 500;
      color: #5c5c5c;
      display: block;
      width: 100%;
      text-align: center;
    }
    > h2 {
      font-size: 39px;
      font-weight: 600;
      color: #5c5c5c;
      line-height: 90px;
      padding-bottom: 80px;
    }
    > p {
      font-size: 21px;
      font-weight: 500;
      color: #5c5c5c;
      line-height: 42px;
      padding: 0 23%;
    }
    // flex-direction: column;
  }
  .btn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 357px;
    height: 66px;
    background: #5c5c5c;
    position: relative;
    margin-top: 13%;
    span {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
    }
    img {
      transition: all .3s;
      position: absolute;
      right: 22px;
      width: 42px;
      height: 21px;
    }
  }
  .public_max_left {
    width: 50%;
    height: 100vh;
    //  flex: 1;
    //  display: flex;
    //  flex-wrap: wrap;
  }
  .public_max_right {
    width: 50%;
    height: 100vh;
    .video_box {
      width: 641px;
      height: 360px;
      position: relative;
      margin-top: 200px;
      .video {
        width: 100%;
        height: 100%;
      }
      .video_p {
        position: absolute;
        right: 0;
        font-size: 21px;
        font-weight: 500;
        color: #5c5c5c;
      }
      .video_play {
        position: absolute;
        width: 641px;
        height: 360px;
        top: 0px;
        background: url("./image/video02.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 78px;
          height: 78px;
          cursor: pointer;
          &:hover {
            position: relative;
            opacity: 0.75;
          }
          &:active {
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }
}
</style>
