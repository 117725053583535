<template>
  <div class="swiper_group">
    <div class="swiper_1">
      <div data-aos="fade-up" class="box_pu box1"></div>
      <div data-aos="fade-up" class="box_pu box2"></div>
      <div data-aos="fade-up" class="box_pu box3"></div>
      <div class="swiper_fix">
        <div data-aos="fade-up" class="swiper_fix_left">
          <div class="img_bg_public img_bg_public_s1"></div>
          <!-- img_height -->
          <!-- <div :class="newIndex===0 ? 'img_height':''" class="abcImg img_bg_public img_bg_public_s2 "></div> -->
        </div>
        <div data-aos="fade-up" class="swiper_fix_right"  data-aos-duration="1600">
          <div class="swiper_right_title">
            <span class="swiper_right_span">策展人说</span>
            <div class="swiper_right_box"></div>
          </div>
          <p data-aos="fade-up">
           中国的工笔花鸟画滥觞自魏晋，成熟于唐，辉煌于五代两宋，新中国成立以来，具有深厚传统积淀的工笔花鸟画焕发出了新风貌。湖南工笔花鸟画中呈现出的湖湘风韵在全国独树一帜，展示出精研传统、开拓进取的艺术精神。
          </p>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="swiper_2">
      <div data-aos="fade-up" class="box_pu box1"></div>
      <div class="swiper_fix">
        <div class="swiper_fix_left">
          <p  class="" data-aos="fade-up" data-aos-duration="500" >
            邹传安先生是湖南乃至全国工笔花鸟画界的一位举足轻重的画家，形成了自己风格独特的“邹氏花鸟”，其画风引领了一批国内工笔画家，为中国工笔画的当代转型奠定了基石。中国美协主席范迪安先生曾评价其艺术在三个方面达到了新的高度：一是取象造型不是借赖画谱典籍；二是布势造境的视野极为开阔；三是在写照传神中注入精湛功力。原中国工笔画学会会长林凡先生曾在学术研讨会上对其高度评价，“这是我们湖南的骄傲，也是中国工笔画界的骄傲”。邹传安先生以宽厚的人生情怀、忘却前人与自我的艺术魄力，将精湛的技巧、独到的审美和人生的感悟融入花鸟，化作“花魂鸟魄”。
          </p>
        </div>
        <div class="swiper_fix_right">
          <div   data-aos="fade-up"
              data-aos-duration="1000" class="swiper_fix_right_box">
            <div class=" swiper_fix_right_box_item img_bg_public_s3"></div>
            <!-- <div :class="newIndex===1 ? 'img_height':''"  class="abcImg swiper_fix_right_box_item img_bg_public_s4"></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- swiper_3 -->
    <div class="swiper_3">
      <div data-aos="fade-up" class="box_pu box1"></div>
      <div class="swiper_fix">
        <div class="swiper_fix_left">
          <div data-aos="fade-up"  class="swiper_fix_left_box">
            <div class=" img_bg_public_s5"></div>
            <!-- <div :class="newIndex===2 ? 'img_height':''"  class="abcImg img_bg_public_s6"></div> -->
          </div>
        </div>
        <div class="swiper_fix_right">
          <p class=""  data-aos="fade-up">
            2013年、2021年，邹传安先生曾向我馆捐赠其各时期代表作及画稿、手稿等共计千余件，这批作品形成了我馆针对他个人的系统收藏，并充实与延续了我馆的“湖南名家书画收藏”系列。经过系统地整理与研究，我馆推出“花魂鸟魄——邹传安艺术与文献展”，较为全面地展示邹传安先生从艺六十余年的艺术历程、艺术特色与成就，共展出书画作品和文献资料三百余件（套）。我们希望通过该个案，推动湘籍及在湘艺术家研究，续写湖南艺术史，并为20世纪以来的工笔花鸟画传承与发展提供有益参考。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: ['newIndex']
}
</script>

<style lang="less">
.swiper_group {
  background: #f4f3f1;
  position: relative;
     @media (min-width: 1400px) {
        .swiper_2 {
          padding-top: 200px;
        }
        .swiper_3 {
          padding-top: 200px;
        }
     }
     @media (min-width: 1800px) {
        .swiper_2 {
          padding-top: 300px;
        }
        .swiper_3 {
          padding-top: 300px;
        }
     }
      .swiper_right_title {
        width:500px;
        display: flex;
        position: absolute;
        right: 0;
        top: 10%;
        z-index: 99;
        .swiper_right_span {
          font-size: 48px;
          font-weight: 600;
          color: #5c5c5c;
          padding-right: 40px;
        }
        .swiper_right_box {
          width: 140px;
          height: 140px;
          background: #5c5c5c;
        }
      }
      .img_height {
        height: 1125px !important;
      }
  .swiper_1 {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .box_pu {
      width: 150px;
      height: 236px;
    }
    .box1{
      position: absolute;
      background: #B4DBCF;
      left: 150px;
      top: 0;
    }
    .box2{
      position: absolute;
      background: #EAC06B;
      right: 226px;
      top: -118px;
    }
    .box3{
      position: absolute;
      background: #5C5C5C;
      left: 0px;
      height: 326px;
      top: 110%;
    }
    .swiper_fix {
      width: 100vw;
      height: 79.5%;
      background: url("./image/swiper/swiper1_bg.png") no-repeat right;
      background-size: 100% 100%;
      display: flex;
      .swiper_fix_left {
        width: 50%;
        height: 100%;
        position: relative;
        .img_bg_public {
          height: 1125px;
          width: 570px;
          position: absolute;
          top: 0px;
          left: 150px;
        }
        .img_bg_public_s1 {
          width: 100%;
          background: url("../components/image/swiper/ss1.png") no-repeat;
          background-size: 100%;
        }
        .img_bg_public_s2 {
          background: url("../components/image/swiper/s1_1.png") no-repeat;
          background-size: 100%;
          height: 0%;
          transition: all 3s;
        }
        // img {
        //   height: 1125px;
        //   width: 570px;
        //   position: absolute;
        //   top: 0px;
        //   left: 150px;
        //   object-fit: none;
        // }
      }
      .swiper_fix_right {
        width: 50%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-items: center;
        > p {
          font-size: 21px;
          font-weight: 500;
          color: #5c5c5c;
          line-height: 42px;
          padding: 55% 150px 0 150px;
        }
      }
    }
  }
  .swiper_2 {
    width: 100vw;
    height: 100vh;
    // background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .box_pu {
      position: absolute;
      width: 150px;
      height: 236px;
    }
    .box1 {
      background: #B4DBCF;
      top: 180px;
      left: 60%;
      height: 272px;
    }
    .swiper_fix {
      width: 100vw;
      height: 100%;
      background-size: 100% 100%;
      display: flex;
    }
    .swiper_fix_left {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        padding: 0 150px;
        padding-top: 41.9%;
        font-size: 21px;
        font-weight: 500;
        color: #5c5c5c;
        line-height: 42px;
      }
    }
    .swiper_fix_right {
      width: 50%;
      height: 100%;
      position: relative;
      .swiper_fix_right_box {
        width: 100%;
        height: 1125px;
        position: absolute;
        top: 76px;
        right: 0;
      }
      .swiper_fix_right_box_item {
          width: 100%;
          height: 1125px;
          object-fit: cover;
          position: absolute;
          right: 0;
          top: 0;
      }
      .img_bg_public_s3 {
          width: 100%;
          background: url("../components/image/swiper/ss2.png") no-repeat;
          background-size: 100%;
      }
      .img_bg_public_s4{
          background: url("../components/image/swiper/s2_1.png") no-repeat;
          background-size: 100%;
          transition: all 3s;
          height: 0;
      }
    }
  }
  .swiper_3 {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // background: orange;
    .box_pu {
      width: 150px;
      height: 412px;

    }
    .box1 {
      position: absolute;
      background: #B4DBCF;
      left: 0;
      top: 0;
    }
    .swiper_fix {
      width: 100vw;
      // height: 100%;
      height: 100vh;
      background-size: 100% 100%;
      display: flex;
    }

    .swiper_fix_left_box {
      width: 100%;
      height: 1125px;
      // height: 100%;
      // background: red;
      position: relative;
      .img_bg_public_s5 {
         width: 100%;
         height: 1125px;
          position: absolute;
          left: 150px;
          top: 0;
          z-index: 10;
          background: url("../components/image/swiper/ss3.png") no-repeat;
          background-size: 100%;
      }
      .img_bg_public_s6{
          width: 517px;
          height: 1125px;
          position: absolute;
          left: 150px;
          top: 0;
          z-index: 10;
          background: url("../components/image/swiper/s3_1.png") no-repeat;
          background-size: 100%;
          height: 0%;
          transition: all 3s;
      }
    }
    .swiper_fix_left {
      width: 50%;
      height: 100%;
      position: relative;
    }
    .swiper_fix_right {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 21px;
        font-weight: 500;
        color: #5c5c5c;
        line-height: 42px;
        padding: 0 20%;
        padding-top: 25%;
      }
    }
  }
}
</style>
