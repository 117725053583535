// eslint-disable-next-line import/prefer-default-export
export const menuData = [
  {
    url: require('../image/grey/img01.png'),
    colorUrl: require('../image/colour/img01.png')
  },
  {
    url: require('../image/grey/img02.png'),
    colorUrl: require('../image/colour/img02.png')
  },
  {
    url: require('../image/grey/img03.png'),
    colorUrl: require('../image/colour/img03.png')
  },
  {
    url: require('../image/grey/img04.png'),
    colorUrl: require('../image/colour/img04.png')
  },
  {
    url: require('../image/grey/img05.png'),
    colorUrl: require('../image/colour/img05.png')
  },
  {
    url: require('../image/grey/img06.png'),
    colorUrl: require('../image/colour/img06.png')
  },
  {
    url: require('../image/grey/img07.png'),
    colorUrl: require('../image/colour/img07.png')
  },
  {
    url: require('../image/grey/img08.png'),
    colorUrl: require('../image/colour/img08.png')
  },
  {
    url: require('../image/grey/img09.png'),
    colorUrl: require('../image/colour/img09.png')
  },
  {
    url: require('../image/grey/img10.png'),
    colorUrl: require('../image/colour/img10.png')
  },
  {
    url: require('../image/grey/img11.png'),
    colorUrl: require('../image/colour/img11.png')
  },
  {
    url: require('../image/grey/img12.png'),
    colorUrl: require('../image/colour/img12.png')
  },
  {
    url: require('../image/grey/img13.png'),
    colorUrl: require('../image/colour/img13.png')
  },
  {
    url: require('../image/grey/img14.png'),
    colorUrl: require('../image/colour/img14.png')
  },
  {
    url: require('../image/grey/img15.png'),
    colorUrl: require('../image/colour/img15.png')
  },
  {
    url: require('../image/grey/img16.png'),
    colorUrl: require('../image/colour/img16.png')
  },
  {
    url: require('../image/grey/img17.png'),
    colorUrl: require('../image/colour/img17.png')
  },
  {
    url: require('../image/grey/img18.png'),
    colorUrl: require('../image/colour/img18.png')
  },
  {
    url: require('../image/grey/img19.png'),
    colorUrl: require('../image/colour/img19.png')
  },
  {
    url: require('../image/grey/img20.png'),
    colorUrl: require('../image/colour/img20.png')
  },
  {
    url: require('../image/grey/img21.png'),
    colorUrl: require('../image/colour/img21.png')
  },
  {
    url: require('../image/grey/img22.png'),
    colorUrl: require('../image/colour/img22.png')
  },
  {
    url: require('../image/grey/img23.png'),
    colorUrl: require('../image/colour/img23.png')
  }
]
