<template>
  <div class="public_max3">
    <div class="public_max_left">
      <div class="public_max_left_title">
        <div class="public_max_left_box ">
        </div>
        <div class="public_max_left_h1">
          <span class="">花踪叶影</span>
          <!-- 走出 -->
          <!-- <p  class=""> </p> -->
        </div>
      </div>
      <div class="text_info">
        <span   data-swiper-parallax="-150"  data-swiper-parallax-duration="600">- 03 -</span>
        <h2   data-swiper-parallax="-200"  data-swiper-parallax-duration="800" >宋人檐外求生活</h2>
        <p   data-swiper-parallax="-300"  data-swiper-parallax-duration="1100" >以泼彩背景取代传统工笔花鸟画的背景留白；引 入光的视角来丰富画面色彩与层次；将花鸟置于 更宏阔的山水当中，增强画面的情景感。</p>
        <div  data-swiper-parallax="-400"   data-swiper-parallax-duration="1200"  @click="handleInfoById({id:4,name:'花踪叶影',label:'走出',info:'宋人檐外求生活'})" class="btn_box btn_hover ">
          <span>全部作品</span>
          <img src="./image/btn_r.png" alt="">
        </div>
      </div>
    </div>
    <div class="public_max_right">
      <img data-swiper-parallax="-20" data-swiper-parallax-opacity="0.5"  data-swiper-parallax-scale="1.05" data-swiper-parallax-duration="1200"  src="./image/max/max2.png" alt="" srcset="">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 跳转到详情
     */
    handleInfoById (query) {
      this.$emit('goInfoById', query)
      // this.$router.push({ path: '/list', query: query })
    }
  }
}
</script>

<style scoped lang="less">
  .public_max3 {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    .btn_hover {
      &:hover {
         cursor: pointer;
         transition: all 1s;
         background: orange;
         img {
           width: 62px;
         }
      }
      &:active {
        position: relative;
        top: 1px;
      }
    }
    .text_info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding-top: 9.5%;
      >span {
        font-size: 27px;
        font-weight: 500;
        color: #fff;
        display: block;
        width: 100%;
        text-align: center;
      }
      >h2 {
        font-size: 39px;
        font-weight: 600;
        color: #5C5C5C;
        line-height: 140px;
      }
      >p {
        font-size: 21px;
        font-weight: 500;
        color: #5C5C5C;
        line-height: 42px;
        padding: 0 22%;
        text-align: center;
      }
      // flex-direction: column;
    }
    .btn_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 357px;
      height: 66px;
      background: #5C5C5C;
      position: relative;
      margin-top: 13%;
      span {
        font-size: 24px;
        font-weight: normal;
        color: #FFFFFF;
      }
      img {
        transition: all .3s;
        position: absolute;
        right: 22px;
        width: 42px;
        height: 21px;
      }
    }
    .public_max_left_title {
      display: flex;
      height: 140px;
      margin-top: 6.5%;
      overflow: hidden;
      .public_max_left_box {
        width: 140px;
        height: 140px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        background: #fff;
      }
      .public_max_left_h1 {
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        height: 140px;
        overflow: hidden;
        padding-left: 5%;
        // align-items: center;
        span {
          font-size: 48px;
          font-weight: 600;
          color: #5C5C5C;
        }
        p{
            font-size: 39px;
            font-weight: 600;
            color: #fff;
            position: relative;
            &::before {
              content: ' 1';
              width: 28px;
              height: 60px;
              background: url('./image/line_w.png') no-repeat;
              background-size: 100% 100%;
              color: transparent;
            }
        }
      }
    }
    .public_max_left {
       width: 50%;
       height: 100vh;
        background: #EAC06B;

      //  flex: 1;
      //  display: flex;
      //  flex-wrap: wrap;
    }
    .public_max_right {
      width: 50%;
      height: 100vh;
      background: #fff;
      overflow: hidden;
      >img{
          height: 100%;
          width: 130%;
          object-fit: cover;
      }
    }
  }
</style>
