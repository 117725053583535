<template>
  <div class="hong_zhua">
    <div class="hong_zhua_gr1">
      <div  data-aos="fade-up" class="hong_zhua_title wow fadeInUp fadeIn">
        <div data-aos="fade-up" class="hong_zhua_title_box wow fadeInUp fadeIn"></div>
        <span  data-aos="fade-up" data-aos-duration="1400" data-aos-delay="200" class="wow fadeInUp fadeIn">雪泥鸿爪</span>
      </div>
      <div class="hongzhua_item1">
        <div class="hongzhua_group">
          <span data-aos="fade-up" data-aos-delay="200" class="wow fadeInUp fadeIn">/ 书法</span>
          <p data-aos="fade-up" data-aos-delay="300" class="wow fadeInUp fadeIn">千载谁是能书人</p>
          <div data-aos="fade-up"  @click="handleInfoById({id:7,name:'雪泥鸿爪',label:'书法',info:'千载谁是能书人'})" class="btn_box btn_hover wow fadeInUp fadeIn">
            <span>全部作品</span>
            <img src="./image/btn_r.png" alt="" />
          </div>
        </div>
        <ol>
          <li data-aos="fade-up" data-aos-duration="600" class="delay_3">
            <img  class="wow fadeInUp fadeIn" src="./image/hongzhua/item1_1.png" alt="" srcset="" />
            <p  class="wow fadeInUp fadeIn">“题画”横幅</p>
          </li>
          <li data-aos="fade-up" data-aos-duration="1000" class="delay_1">
            <img class="wow fadeInUp fadeIn" src="./image/hongzhua/item1_2.png" alt="" srcset="" />
            <p  class="wow fadeInUp fadeIn">王安石《浪淘沙令》横幅</p>
          </li>
          <li data-aos="fade-up" data-aos-duration="1400" class="delay_5">
            <img  class="wow fadeInUp fadeIn" src="./image/hongzhua/item1_3.png" alt="" srcset="" />
            <p  class="wow fadeInUp fadeIn">“且留余地”横幅</p>
          </li>
        </ol>
      </div>
    </div>
    <div class="hong_zhua_gr2">
      <div class="hongzhua_item1">
        <div class="hongzhua_group">
          <span  data-aos="fade-up"  class="wow fadeInUp fadeIn">/ 写意画 </span>
          <p  data-aos="fade-up"  class="wow fadeInUp fadeIn">工写勾泼任翕张</p>
          <div   data-aos="fade-up"  @click="handleInfoById({id:8,name:'雪泥鸿爪',label:'写意画',info:'工写勾泼任翕张'})"  class="btn_box btn_hover wow fadeInUp fadeIn">
            <span>全部作品</span>
            <img src="./image/btn_r.png" alt="" />
          </div>
        </div>
        <ol>
          <li  data-aos="fade-up" data-aos-duration="800" >
            <img class="wow fadeInUp fadeIn" src="./image/hongzhua/item2_1.png" alt="" srcset="" />
            <p class="wow fadeInUp fadeIn">东篱清韵</p>
          </li>
          <li  data-aos="fade-up" data-aos-duration="1000" >
            <img class="wow fadeInUp fadeIn" src="./image/hongzhua/item2_2.png" alt="" srcset="" />
            <p class="wow fadeInUp fadeIn">花月无言</p>
          </li>
          <li  data-aos="fade-up" data-aos-duration="1200" >
            <img class="wow fadeInUp fadeIn" src="./image/hongzhua/item2_3.png" alt="" srcset="" />
            <p class="wow fadeInUp fadeIn">来客何为</p>
          </li>
          <li  data-aos="fade-up" data-aos-duration="1400" >
            <img class="wow fadeInUp fadeIn" src="./image/hongzhua/item2_4.png" alt="" srcset="" />
            <p class="wow fadeInUp fadeIn">久安图</p>
          </li>
        </ol>
      </div>
    </div>
    <ul class="hong_zhua_ul">
      <li></li>
      <li class="li_2"></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    /**
     * 跳转到详情
     */
    handleInfoById (query) {
      this.$emit('goInfoById', query)
      // this.$router.push({ path: '/list', query: query })
    }
  }
}
</script>

<style lang="less">
.hong_zhua {
  width: 100vw;
  background: #f4f3f1;
  height: 2600px;
  position: relative;
  .hong_zhua_gr1 {
    // height: 1200px;
    .hongzhua_item1 {
      padding-left: calc(100vw / 6);
      position: relative;
      .hongzhua_group {
        padding-top: 66px;
        > span {
          display: block;
          font-size: 27px;
          font-weight: 600;
          color: #b4dbcf;
        }
        > p {
          padding: 5px 0;
          font-size: 39px;
          font-weight: 600;
          color: #5c5c5c;
          padding-bottom: 700px;
        }
      }
      ol {
        position: absolute;
        padding-left: calc(100vw / 6);
        top: 164px;
        li {
          z-index: 10;
          p {
            text-indent: 1em;
          }
          &:nth-child(1) {
            width: calc(100vw / 6);
            position: absolute;
            top: 236px;
            img {
              width: 100%;
              height: 206px;
              display: block;
            }
            p {
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
              padding-top: 21px;
            }
          }
          &:nth-child(2) {
            width: calc(100vw / 2);
            position: absolute;
            left: calc(100vw / 3);
            img {
              width: 100%;
              height: 236px;
              display: block;
            }
            p {
              padding-top: 21px;
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
            }
          }
          &:nth-child(3) {
            width: calc(100vw / 3);
            position: absolute;
            left: calc(100vw / 2);
            top: 366px;
            img {
              height: 332px;
              width: 100%;
              display: block;
            }
            p {
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
              padding-top: 21px;
            }
          }
        }
      }
    }
  }
  .hong_zhua_gr2 {
      padding-top: 60px;
    .hongzhua_item1 {
      padding-left: calc(100vw / 6);
      position: relative;
      .hongzhua_group {
        padding-top: 66px;
        > span {
          display: block;
          font-size: 27px;
          font-weight: 600;
          color: #b4dbcf;
        }
        > p {
          padding: 5px 0;
          font-size: 39px;
          font-weight: 600;
          color: #5c5c5c;
          padding-bottom: 700px;
        }
      }
      ol {
        position: absolute;
        padding-left: calc(100vw / 6);
        top: 164px;
        li {
          p {
            text-indent: 1em;
          }
          z-index: 10;
          &:nth-child(1) {
            width: calc(100vw / 6);
            position: absolute;
            top: 146px;
            left: 0;
            img {
              width: 100%;
              height: 486px;
              display: block;
            }
            p {
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
              padding-top: 21px;
            }
          }
          &:nth-child(2) {
            width: calc(100vw /6);
            position: absolute;
            left: calc(100vw / 6);
            top: 72px;
            img {
              width: 100%;
              height: 633px;
              display: block;
            }
            p {
              padding-top: 21px;
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
            }
          }
          &:nth-child(3) {
            width: calc(100vw / 6);
            position: absolute;
            left: calc(100vw / 3);
            top: 306px;
            img {
              height: 523px;
              width: 100%;
              display: block;
            }
            p {
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
              padding-top: 21px;
            }
          }
          &:nth-child(4) {
            width: calc(100vw / 3);
            position: absolute;
            left:calc(100vw / 2);
            top: 226px;
            img {
              height: 523px;
              width: 100%;
              display: block;
            }
            p {
              font-size: 21px;
              font-weight: 500;
              color: #5c5c5c;
              padding-top: 21px;
            }
          }
        }
      }
    }
  }
  .btn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw / 6);
    height: 66px;
    background: #5c5c5c;
    position: relative;
    margin-top: 3%;
    span {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
    }
    img {
      transition: all .3s;
      position: absolute;
      right: 22px;
      width: 42px;
      height: 21px;
    }
  }
  .hong_zhua_title {
    display: flex;
    .hong_zhua_title_box {
      width: 159px;
      height: 159px;
      background: #b4dbcf;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    > span {
      height: 68px;
      font-size: 48px;
      font-weight: 600;
      color: #5c5c5c;
      padding-left: 48px;
      padding-top: 92px;
    }
  }
  .hong_zhua_ul {
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: flex;
    li {
      // border-right: 1px solid #ccc;
      width: calc(100vw / 6);
      height: 100%;
      box-sizing: border-box;
      &:last-of-type {
        border: none;
      }
    }
  }
}
</style>
