
<template>
  <div @mousewheel="mouseWheel" class="home_list">
    <!-- <div class="swiper_right_title">
          <span class="swiper_right_span">策展人说</span>
          <div class="swiper_right_box"></div>
    </div> -->
    <!-- <div class="hander_box animated fadeInDown">
      <div class="hander_l" @click="changeShowMenu">
        <img src="../assets/mylist/list2.png" alt="" srcset="" />
        <span>导航</span>
      </div>
      <p class="hander_r"></p>
    </div> -->
    <MenuTemp ref="refMenuTemp" @toPage="toPageScrollTo" v-model="navTag"></MenuTemp>
    <!-- 内容区域 slideInUp -->
    <div class="content">
      <div class="content_item" id="content_item1">
        <!-- <HomeHander @toNext="toNext" /> -->
        <div class="bg_div">
            <div @click.stop="changeShowMenu" :class="navTag ? 'navTag':null" class="bg_div_right">
               <span>导航</span>
               <img v-if="!navTag" class="open" src="../views/components/image/nav/down.png" alt="" srcset="" />
               <img v-else class="close" src="../views/components/image/nav/close.png" alt="" srcset="" />
            </div>
        </div>
      </div>
      <div class="content_item">
        <OSwiper :newIndex="newIndex" />
      </div>
      <div class="content_item">
        <CVideo />
      </div>
      <div class="content_item swiper-container initSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide"><Item01 @goInfoById="goInfoById" /></div>
          <div class="swiper-slide"><Item02 ref="item02" @goInfoById="goInfoById" /></div>
          <div class="swiper-slide"><Item03 @goInfoById="goInfoById" /></div>
          <div class="swiper-slide"><Item04 @goInfoById="goInfoById" /></div>
        </div>
      </div>
      <div class="content_item">
        <Manuscript1 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <Manuscript2 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <Manuscript3 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <FooterItem01 @goInfoById="goInfoById" />
      </div>
      <div class="content_item">
        <FooterItem02 @goInfoById="goInfoById" />
      </div>
      <div id="content_item">
        <IndexFooter @goTop="goToTop" />
      </div>
    </div>
    <!-- 导航区域 -->
    <ul class="navs">
      <li
        v-for="(item, index) in 9"
        :key="index"
        :class="{ active: active === index }"
        @click="scrollTo(index)"
      ></li>
      <!-- <li :class="{ active: active === 1 }" @click="scrollTo(1)"></li>
      <li :class="{ active: active === 2 }" @click="scrollTo(2)"></li>
      <li :class="{ active: active === 3 }" @click="scrollTo(3)"></li>
      <li :class="{ active: active === 4 }" @click="scrollTo(4)"></li>
      <li :class="{ active: active === 5 }" @click="scrollTo(5)"></li>
      <li :class="{ active: active === 6 }" @click="scrollTo(6)"></li> -->
    </ul>
  </div>
</template>

<script>
import $ from 'jquery'
// import WOW from 'wowjs'
// import xuhao from './components/data/xuhao'
import HomeHander from './components/Hander.vue'
import CVideo from './components/CVideo.vue'
import FooterItem01 from './components/FooterItem01.vue'
import FooterItem02 from './components/FooterItem02.vue'
import HongZao from './components/HongZao.vue'
import IndexFooter from './components/IndexFooter.vue'
import Manuscript1 from './components/Manuscript1.vue'
import Manuscript2 from './components/Manuscript2.vue'
import Manuscript3 from './components/Manuscript.vue'
import Item01 from './components/item01.vue'
import Item02 from './components/item02.vue'
import Item03 from './components/item03.vue'
import Item04 from './components/item04.vue'
import OSwiper from './components/swiper.vue'
import MenuTemp from '@/views/components/MenuTemp'
import AOS from 'aos'

export default {
  props: {},
  data () {
    return {
      navTag: false,
      preventDefault: false, // 是不是阻止滚动事件
      swiperIndex: 0, // 切换到哪一个索引来了
      initswiper: null, // 滚动切换
      wheel: null,
      active: 0, // 当前激活的导航索引
      newIndex: null // 第二页的执行index
    }
  },
  components: {
    HomeHander,
    FooterItem01,
    FooterItem02,
    IndexFooter,
    HongZao,
    Manuscript1,
    Manuscript2,
    Manuscript3,
    Item01,
    Item02,
    Item03,
    Item04,
    CVideo,
    OSwiper,
    MenuTemp
  },
  created () {
  },
  mounted () {
    this.sor()
    // console.log('xuhao...', xuhao)
    // 监听滚动事件
    window.addEventListener('scroll', this.onScroll, false)
    // new WOW.WOW().init({ offset: 0 })
    AOS.init()
    if (this.$route.query && this.$route.query.page) {
      this.scrollTo(Number(this.$route.query.page))
    }
    if (this.$route.query.index >= 0) {
      setTimeout(() => {
        this.swiperIndex = this.$route.query.index
        this.slideTo(this.$route.query.index)
      }, 1000)
    }
  },
  destroy () {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.onScroll)
  },
  watch: {
    active (newValue) {
      if (newValue > 4) {
        this.preventDefault = false
      }
    },
    newIndex (index) {
      // console.log(index)
    },
    swiperIndex (index) {
      this.scrollTo(3)
      if (index === 0) {
        this.preventDefault = false
      }
    }
  },
  methods: {
    toNext () {
      this.scrollTo(1)
      $('.home_list .swiper_right_title').css({ opacity: 1 }).addClass('animated fadeInUp')
    },
    slideTo (index = 0) { // 跳转到那个页面
      this.initswiper.slideTo(index, 1000, false)
    },
    sor () {
      const _this = this
      this.initswiper = new Swiper('.initSwiper', {
        direction: 'vertical',
        followFinger: false,
        speed: 200,
        effect: 'fade',
        mousewheel: true,
        // mousewheel: {
        //   thresholdTime: 1000
        // },
        parallax: true,
        // invert: true,
        // mousewheel: {
        //   releaseOnEdges: true // PC端释放滑动
        // },
        pagination: {
          // el: '.swiper-pagination'
        },
        on: {
          slideChangeTransitionEnd: function () {
            // console.log('this.activeIndex...', this.activeIndex)
            _this.swiperIndex = this.activeIndex
          },
          slideChangeTransitionStart: function () {
            // if (this.activeIndex === 1) {
            //   _this.$refs.item02.getSwiperAn()
            // }
            // if (this.activeIndex === 2) {
            //   // _this.$refs.item02.getSwiperAn()
            // }
            // if (this.activeIndex === 3) {
            //   // _this.$refs.item02.getSwiperAn()
            // }
          }
        }
      })
    },
    changeShowMenu () {
      this.$refs.refMenuTemp.changeShowMenu()
    },
    mouseWheel (e) {
      if (this.preventDefault) {
        e.preventDefault()
      }
      if (e.wheelDelta || e.detail) {
        if (e.wheelDelta > 0 || e.detail < 0) {
          // 当鼠标滚轮向上滚动时
          // console.log('向上')
          this.wheel = 'top'
          if (this.active === 0) {
            this.scrollTo(0)
            $('.home_list .swiper_right_title').css({ opacity: 0 }).removeClass('animated fadeInUp')
            $('.home_list .hander_box')
              .removeClass('fadeInUp fadeOutUp')
              .addClass('fadeInUp')
          } else {
            $('.home_list .hander_box')
              .removeClass('fadeInUp fadeOutUp')
              .addClass('fadeOutUp')
          }
          if (this.active === 1) {
            $('.home_list .swiper_right_title').css({ opacity: 1 }).addClass('animated fadeInUp')
          }
          if (this.active === 3 && this.swiperIndex === 3) { // 向上返回滚动轮播图最后一页
            this.preventDefault = true
            setTimeout(() => {
              this.scrollTo(3)
            }, 100)
            // this.slideTo(2)
          }
          if (this.active === 3 && this.swiperIndex === 0) { // 向上返回滚动轮播图第一页
            setTimeout(() => {
              this.scrollTo(2)
            }, 100)
          }
        }
        if (e.wheelDelta < 0 || e.detail > 0) {
          // 当鼠标滚轮向下滚动时
          // console.log('向下')
          this.wheel = 'down'
          $('.home_list .hander_box')
            .removeClass('fadeInUp fadeOutUp')
            .addClass('fadeInUp')
          if (this.active === 0) {
            this.scrollTo(1)
            // $('.home_list .swiper_right_title').css({ opacity: 1 }).removeClass('animated fadeInUp')
            // $('.home_list .swiper_right_title').addClass('animated fadeInUp')
          }
          if (this.active === 2) {
            // $('.home_list .swiper_right_title').css({ opacity: 0 }).removeClass('animated fadeInUp')
            const scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop
            // console.log('scrollTop...', scrollTop)
            const offsetT = document.querySelector('#page2_an_4').offsetTop
            // console.log('offsetT...', offsetT)
            if (scrollTop >= offsetT) { // 跳转到
              this.preventDefault = true
              setTimeout(() => {
                this.scrollTo(3)
              }, 200)
              // this.slideTo(2)
            }
          }
          if (this.active === 3 && this.swiperIndex === 3) {
            this.preventDefault = false
            setTimeout(() => {
              this.scrollTo(4)
            }, 200)
          }
        }
      }
    },
    goToTop () {
      $('body, html').animate({ scrollTop: 0 }, 2000)
    },
    goInfoById (query) {
      this.$router.push({ path: '/list', query: query })
    },
    // 滚动监听器
    onScroll () {
      // 获取当前文档流的 scrollTop
      const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.content >div')
      // 获取自定义的几个喵点
      const newContents = document.querySelectorAll('.swiper_group >div')
      // console.log('.swiper_group...', $('.swiper_group .swiper_1').offsetTop)
      const newOffsetArr = []
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop)
      })
      newContents.forEach((item) => {
        newOffsetArr.push(item.offsetTop + 160)
      })
      // console.log('newContents..', newContents)
      // console.log('newOffsetArr...', scrollTop, newOffsetArr)
      // 定义当前点亮的导航下标
      let newIndex = 0
      for (let n = 0; n < newOffsetArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= newOffsetArr[n]) {
          newIndex = n
        }
        if (
          scrollTop + document.documentElement.clientHeight ===
          document.documentElement.scrollHeight
        ) {
          newIndex = offsetTopArr.length - 1
        }
      }
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第n个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是n了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
        if (
          scrollTop + document.documentElement.clientHeight ===
          document.documentElement.scrollHeight
        ) {
          navIndex = offsetTopArr.length - 1
        }
      }
      this.active = navIndex
      this.newIndex = newIndex
      // console.log('newIndexAAAA...', newIndex)
    },
    toPageScrollTo (item) {
      if (item.page) {
        this.scrollTo(Number(item.page))
      }
      if (item.index >= 0) {
        setTimeout(() => {
          this.swiperIndex = item.index
          this.slideTo(item.index)
        }, 1000)
      }
    },
    // 跳转到指定索引的元素
    scrollTo (index) {
      // console.log(index)
      // 获取目标的 offsetTop
      // css选择器是从 1 开始计数，我们是从 0 开始，所以要 +1
      const targetOffsetTop = document.querySelector(
        `.content >div:nth-child(${index + 1})`
      ).offsetTop
      // 获取当前 offsetTop
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉，步子迈大了会扯到蛋
      const STEP = 100
      // 判断是往下滑还是往上滑
      if (scrollTop > targetOffsetTop) {
        // 往上滑
        smoothUp()
      } else {
        // 往下滑
        smoothDown()
      }
      // 定义往下滑函数
      function smoothDown () {
        // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
        if (scrollTop < targetOffsetTop) {
          // 如果和目标相差距离大于等于 STEP 就跳 STEP
          // 否则直接跳到目标点，目标是为了防止跳过了。
          if (targetOffsetTop - scrollTop >= STEP) {
            scrollTop += STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
          requestAnimationFrame(smoothDown)
        }
      }
      // 定义往上滑函数
      function smoothUp () {
        if (scrollTop > targetOffsetTop) {
          if (scrollTop - targetOffsetTop >= STEP) {
            scrollTop -= STEP
          } else {
            scrollTop = targetOffsetTop
          }
          document.body.scrollTop = scrollTop
          document.documentElement.scrollTop = scrollTop
          requestAnimationFrame(smoothUp)
        }
      }
    }
  }
}
</script>

<style  lang="less">
  .all_work_btn {
    padding: 5px 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      background: #325158;
      opacity: .75;
    }
    &:active{
        position: relative;
        top: 1px;
      }
    span {
      color: #fff;
      font-size: 25px;

    }
  }
// .img_viewer
 .max_open .swiper-slide{
   justify-content: center;
 }
.max_open .swiper-slide::after {
  width: 350px;
  height: 120px;
  position: absolute;
  content: " ";
  background: url("./components/image/111.png") no-repeat;
  background-size: 100% 100%;
  left: 50%;
  top: 50%;
  margin-left: -175px;
  margin-top: -60px;
  pointer-events: none;
}
.max_open .swiper-slide::before {
  width: 64PX;
  height: 64PX;
  position: absolute;
  content: " ";
  background: url("./components/image/max.png") no-repeat;
  background-size: 100% 100%;
  left: 50%;
  margin-left: -32px;
  bottom: 0%;
  cursor: pointer;
  pointer-events: none;
}
// .swiper_slide_next {
//   width: 394px !important;
//   height: 69vh;
//   background: #848484;
// }
.img_viewer::after {
  width: 350px;
  height: 120px;
  position: absolute;
  content: " ";
  background: url("./components/image/111.png") no-repeat;
  background-size: 100% 100%;
  left: 50%;
  top: 50%;
  margin-left: -175px;
  margin-top: -60px;
}

    .bg_div_right {
      transition: all .3s;
      width: 102px;
      height: 65px;
      background: #325158;
      border-radius: 14px 14px 14px 14px;
      opacity: 0.83;
      position:fixed;
      z-index: 99999;
      top: 28px;
      left: 28px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 28px;
      cursor: pointer;
      &:hover{
        opacity: .75;
      }
      &:active{
        // position: absolute;
        top: 29px;
      }
      >span {
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
      }
      >img {
        width: auto;
        height: 12px;
      }
    }

    .navTag {
      width: 194px !important;
      top: 0 !important;
      left: 0 !important;
      border-radius: 0 !important;
    }
.home_list {
  // width: 100vw;
  background: #f4f3f1;
  // height: 4600px;
  position: relative;
  overflow: hidden;
  .bg_div {
    width: 100%;
    height: 100vh;
    background: url("../views/components/image/nav/nav.png") no-repeat center center;
    background-size: 100%;
    position: relative;
  }
  // .swiper_right_title {
  //   display: flex;
  //   position: fixed;
  //   right: 0;
  //   top: 224px;
  //   z-index: 1999;
  //   opacity: 0;
  //   .swiper_right_span {
  //     font-size: 48px;
  //     font-weight: 600;
  //     color: #5c5c5c;
  //     padding-right: 40px;
  //   }
  //   .swiper_right_box {
  //     width: 140px;
  //     height: 140px;
  //     background: #5c5c5c;
  //   }
  // }
}
/* 内容区的样式 */
.content {
  // background-color: white;
  // width: 500px;
}
/* 导航栏的样式 */
.navs {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100000;
  right: 10px;
}
.navs li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #b1b1b1;
  margin-bottom: 30px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}
/* 当导航被点亮后改变颜色 */
.navs .active {
  background: #5c5c5c;
}

//
.btn_hover {
  &:hover {
    cursor: pointer;
    transition: all 1s;
    background: orange;
    img {
      width: 62px;
    }
  }
  &:active {
    position: relative;
    top: 1px;
  }
}
.full_page {
  width: calc(100vw - 16px);
  overflow-x: hidden;
  // ::-webkit-scrollbar {display:none}
}
.hander_box {
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1888;
  .hander_r {
    font-size: 36px;
    color: #5c5c5c;
    padding-right: 68px;
  }
  .hander_l {
    padding-left: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 54px;
      height: 15px;
    }
    span {
      font-size: 21px;
      color: #fff;
      // color: #5c5c5c;
      padding-left: 20px;
      display: block;
    }
  }
}
@keyframes scaleKay2 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  20% {
    opacity: 0.4;
    transform: scale(0.6);
  }
  70% {
    opacity: 0.78;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleKay {
  0% {
    opacity: 0;
    transform: scale(2.5);
  }
  // 60%{
  //   opacity: 0.4;
  //   transform: scale(1.8);
  // }
  // 70%{
  //   opacity: 0.75;
  //   transform: scale(1.5);
  // }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.animation2 {
  animation-name: scaleKay2; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  animation-iteration-count: 1; //  动画播放次数，infinite：一直播放
  animation-duration: 1s; // 动画完成时间
}
@iterations: 50;
.span-loop (@i) when (@i > 0) {
  .delay_@{i} {
    @delay: (@i)/10;
    animation-delay: ~"@{delay}s";
  }
  .duration_@{i} {
    @delay: (@i)/1;
    animation-duration: ~"@{delay}s";
  }
  .span-loop(@i - 1);
}
.span-loop (@iterations);
.animation1 {
  position: relative;
  z-index: 99;
  animation-name: scaleKay; // 动画名称
  animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
  // animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
  // animation-delay: 0s; // 动画延迟时间
  animation-iteration-count: 1; //  动画播放次数，infinite：一直播放
  // animation-duration: 2s; // 动画完成时间
}

.initSwiper {
  width: 100%;
  height: 100vh;
  .swiper-wrapper {
    transition-delay: 0.3s;
  }
  .swiper-slide {
    text-align: center;
    // background: red;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
}
</style>
