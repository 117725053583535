<template>
  <div class="footerItem01">
    <div class="public_max_left">
      <div class="text_info">
        <span data-aos="fade-up" class="wow fadeInUp fadeIn"
          >- Artistic process -</span
        >
        <h2 data-aos="fade-up" class="wow fadeInUp fadeIn">艺术年表</h2>
        <p data-aos="fade-up" class="wow fadeInUp fadeIn">
          本单元系统性地梳理了邹传安先生从早年瓷工时期到晚年所达到的辉煌成就，记录其人生中的起伏转折，最终形成了艺术年表，旨在为观众更加直观的体现邹传安先生的艺术人生。
        </p>
        <div
          data-aos="fade-up"
          @click="goInfo"
          class="btn_box btn_hover wow fadeInUp fadeIn"
        >
          <span>详情</span>
          <img src="./image/btn_r.png" alt="" />
        </div>
      </div>
    </div>
    <div class="public_max_right">
      <!-- <div class="year">
        <div  data-aos="fade-up" data-aos-duration="600" class="year_item wow fadeInUp fadeIn delay_1">
          <h1>2022年</h1>
          <p>1月，《工笔花鸟画技法》由深圳海天出版社出版</p>
          <p>4月，向湖南省博物馆捐赠绘画、书法作品和档案共计？</p>
        </div>
        <div  data-aos="fade-up" data-aos-duration="800" class="year_item wow fadeInUp fadeIn delay_3">
          <h1>2020年</h1>
          <p>11月， “八秩回眸——邹传安 书画艺术文献展”在长沙美术 馆展出。</p>
        </div>
        <div  data-aos="fade-up" data-aos-duration="1000" class="year_item wow fadeInUp fadeIn delay_5">
          <h1>2018年</h1>
          <p>9月，《邹传安全集》由深圳海天出版社出版</p>
        </div>
      </div> -->
      <!-- <ul class="hong_zhua_ul">
        <li class="li1">
          <div class="div_top">
            <p data-aos="fade-up">1940年 / 庚辰 1岁</p>
            <p data-aos="fade-up">1943年 / 癸未 3岁</p>
            <p data-aos="fade-up">1945年 / 乙酉 5岁</p>
            <p data-aos="fade-up">1950年 / 庚寅 10岁</p>
            <p data-aos="fade-up">1956年 / 丙申 16岁</p>
          </div>
          <div class="div_bottom">
            <p data-aos="fade-up">1958年 / 戊戌 18岁</p>
            <p data-aos="fade-up">1959年 / 己亥 19岁</p>
          </div>
          <div data-aos="fade-up" class="li1_absolute"></div>
        </li>
        <li class="li2">
          <p data-aos="fade-up">生于湖南省新化县水车镇</p>
          <p data-aos="fade-up">开始习字</p>
          <p data-aos="fade-up">入学读书，性好涂鸦。</p>
          <p data-aos="fade-up">为县镇机构绘制漫画。</p>
          <p  class="p_text" data-aos="fade-up">报考新化瓷厂被录取，学徒期三年。</p>
          <img
            data-aos="fade-up"
            class="head"
            src="../components/image/head.png"
            alt=""
            srcset=""
          />
          <span data-aos="fade-up" class="head_span"
            >*上世纪六十年代学徒的邹传安</span
          >
          <p data-aos="fade-up">新化瓷厂学徒，提前期满入职。</p>
          <p class="p_text" data-aos="fade-up">
            有美术作品参加新化县文化馆组织的展览。
            由厂部通过湖南省轻工业厅保送至醴陵陶瓷研究所学习釉下彩，由林家湖工程师教授线描。
          </p>
        </li>
        <li class="li3"></li>
      </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goInfo () {
      // window.location.href = '/#/licheng'
      this.$router.push({ path: '/licheng' })
    }
  }
}
</script>

<style lang="less">
.footerItem01 {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: #fff;
  .text_info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-top: 14.5%;
    > span {
      font-size: 27px;
      font-weight: 500;
      color: #5c5c5c;
      display: block;
      width: 100%;
      text-align: center;
    }
    > h2 {
      font-size: 39px;
      font-weight: 600;
      color: #5c5c5c;
      line-height: 90px;
      padding-bottom: 80px;
    }
    > p {
      font-size: 21px;
      font-weight: 500;
      color: #5c5c5c;
      line-height: 42px;
      padding: 0 23%;
    }
    // flex-direction: column;
  }
  .btn_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 357px;
    height: 66px;
    background: #5c5c5c;
    position: relative;
    margin-top: 13%;
    span {
      font-size: 24px;
      font-weight: normal;
      color: #ffffff;
    }
    img {
      transition: all 0.3s;
      position: absolute;
      right: 22px;
      width: 42px;
      height: 21px;
    }
  }
  .public_max_left {
    width: 40%;
    height: 100vh;
    //  flex: 1;
    //  display: flex;
    //  flex-wrap: wrap;
  }
  .public_max_right {
    width: 55%;
    height: 100vh;
    position: relative;
    background: url("../../views/components/image/img_list.png") no-repeat center;
    background-size: 100% 100%;
    .year {
      .year_item {
        position: relative;
        z-index: 100;
        padding-left: 4px;
        padding-top: 120px;
        &:nth-child(3n + 2) {
          padding-left: calc(100vw / 3);
        }
        &:nth-child(3n + 3) {
          padding-left: calc(100vw / 6);
        }
        h1 {
          font-size: 66px;
          font-weight: 600;
          color: #5c5c5c;
        }
        p {
          font-size: 18px;
          font-weight: 500;
          color: #5c5c5c;
          line-height: 36px;
        }
      }
    }
    .hong_zhua_ul {
      width: calc(100vw / 2);
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      display: flex;
      li {
        border-left: 0.5px solid #ddd;
        width: calc(100vw / 6);
        height: 100%;
        box-sizing: border-box;
        &:last-of-type {
          // border: none;
        }
      }
      .li1 {
         position: relative;
         padding-top: 60px;
        .li1_absolute {
          width: calc(100vw / 12);
          height: 270px;
          background: #b4dbcf;
          position: absolute;
          bottom: -180px;
          z-index: 1;
          &::after {
            width: 100%;
            height: 90px;
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
            background: #eac06b;
          }
        }
        .div_top {
          padding-left: 28px;
          padding-bottom: 330px;
        }
        .div_bottom {
          padding-left: 28px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          color: #999;
          padding: 16px 0px;
          position: relative;
          z-index: 10;
        }
      }
      .li2 {
        position: relative;
        padding-top: 60px;
        .head {
          width: 91.5%;
          height: 323px;
          margin-left: 28px;
        }
        .head_span {
          font-size: 16px;
          font-weight: 400;
          color: #999;
          margin-left: 28px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          color: #5c5c5c;
          padding: 16px 0px;
          padding-left: 28px;
        }
        .p_text {
          width: calc(100vw / 4.5);
          position: relative;
          display: flex;
        }
      }
    }
    // >img{
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    // }
  }
}
</style>
