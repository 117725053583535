<template>
  <div class="max_open" v-if="showImgList">
    <b class="clear " @click="handleClear">关闭</b>
    <img :src="imgInfo.multi_graph[oIndex]+'?w=2000&h=-'" class="max_open_img" alt="" srcset=""/>
    <div class="swiper">
      <img
        v-if="imgInfo.multi_graph.length > 1"
        @click="click_left"
        class="btn_left"
        src="../../views/components/image/left.png"
        alt=""
      />
      <img
       v-if="imgInfo.multi_graph.length > 1"
        @click="click_right"
        class="btn_right"
        src="../../views/components/image/right.png"
        alt=""
      />
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide blue-slide" v-for="item in imgInfo.multi_graph" :key="item.id">
            <img
              style="width: 100%;max-height: 100%"
              :src="item+'?w=1600&h=-'"
              @click="showImgViewer"
            />
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
      <div class="swiper_footer">
        <div class="swiper_footer_top">
          <div class="swiper_footer_top_left">
            <h4>{{ imgInfo.name }}</h4>
            <p>创作年代：{{ imgInfo.creation_age }}</p>
          </div>
          <div v-if="!show" @click="show = !show" class="swiper_footer_top_right">
            <span>查看详情</span>
            <img src="./down.png" alt="" srcset="">
          </div>
          <div v-if="show" @click="show = !show" class="swiper_footer_top_right">
            <span>关闭</span>
            <img style="height:16px;width:16px;" src="./close.png" alt="" srcset="">
          </div>
        </div>
        <el-image-viewer
          class="img_viewer"
          v-if="imgViewerVisible"
          :on-close="closeImgViewer"
          :url-list="multi_graph"/>
        <div v-if="show" class="swiper_footer_bottom">
          <p>{{ imgInfo.collection_address }}</p>
          <p v-if="imgInfo.annotations">【释文】</p>
          <p>{{ imgInfo.annotations }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {
      mySwiper: null,
      show: false,
      imgInfo: null,
      showImgList: false,
      imgViewerVisible: false,
      oIndex: 0,
      multi_graph: []
    }
  },
  methods: {
    showImgViewer () {
      this.imgViewerVisible = true
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },
    closeImgViewer () {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    },
    click_left () {
      this.mySwiper.slidePrev()
    },
    click_right () {
      this.mySwiper.slideNext()
    },
    open (imgInfo) {
      console.debug(imgInfo)
      const _this = this
      this.imgInfo = imgInfo
      this.multi_graph = imgInfo.multi_graph.map(item => {
        return item + '?w=-&h=-'
      })
      this.showImgList = true
      this.$nextTick(() => {
        // eslint-disable-next-line no-unused-vars
        this.mySwiper = new Swiper('.max_open .swiper-container', {
          autoplay: false, // 等同于以下设置
          pagination: {
            el: '.swiper-pagination'
          },
          on: {
            slideChangeTransitionStart: function () {
              _this.oIndex = this.activeIndex
            }
          }
        })
      })
    },
    handleClear () {
      this.showImgList = false
    }
  },
  mounted () {

  }
}
</script>

<style lang="less">
.el-image-viewer__btn{
  z-index: 100000;

}
.max_open {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1999;

  .el-image-viewer__close{
    z-index: 999999;
  }
  .swiper-pagination-bullet-active {
      background: #999;
  }
  .clear {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: #fff;
    z-index: 999999;
    cursor: pointer;
  }

  .swiper_footer {
    width: 100%;
    // height: 230px;
    // padding: 20px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #333;
    z-index: 9999;
    color: #fff;

    .swiper_footer_top {
      display: flex;
      justify-content: space-between;
      padding-left: 40px;
    }

    .swiper_footer_bottom {
      padding-left: 40px;

      p {
        font-size: 18px;
        line-height: 30px;

        &:nth-child(1) {
          padding-bottom: 20px;
        }
      }
    }

    .swiper_footer_top_left {
      h4 {
        padding-top: 10px;
        font-size: 24px;
      }

      p {
        font-size: 18px;
        line-height: 30px;
        padding-bottom: 10px;
      }
    }

    .swiper_footer_top_right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 276px;
      border-left: 1px solid #fff;
      cursor: pointer;

      span {
        font-size: 24px;
      }

      img {
        width: 18px;
        height: 9px;
        display: block;
        margin-left: 10px;
      }
    }
  }

  .max_open_img {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  .swiper {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.45);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .btn_left {
      width: 70px;
      height: 70px;
      position: absolute;
      left: 50px;
      top: 50%;
      margin-top: -43px;
      margin-top: -35px;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }

      &:active {
        margin-top: -34px;
      }
    }

    .btn_right {
      width: 70px;
      height: 70px;
      position: absolute;
      right: 50px;
      top: 50%;
      margin-top: -35px;

      &:hover {
        cursor: pointer;
        opacity: 0.75;
      }

      &:active {
        margin-top: -34px;
      }
    }
  }

  .swiper-container {
    width: auto!important;
    height: auto!important;
    max-width: 72%;
    max-height: 70%;
    margin: auto auto;
    overflow: hidden !important;
    // border: 1px solid peru;
    position: absolute !important;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    line-height: 300px;
    font-size: 50px;
    text-align: center;
    color: white;
    display:flex;
    margin-right: 0 !important;
    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain
    }
  }

  .blue-slide {
  }

  .red-slide {
    background-color: indianred;
  }

  .orange-slide {
    background-color: orange;
  }
}
</style>
